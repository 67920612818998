import * as React from "react";
import Layout from "../components/layout/layout";
import { SeoES, NoIndex } from "../components/seo";
import "../assets/css/index.css";
import { translations } from "../locales/cookiesPolicy.js";

const CookiePolicyPage = () => {
  return (
    <Layout page="cookies" language="es">
      <main className="page-container container">
        <div className="page">
          <h1 className="page-title">{translations.es.title}</h1>
          <p className="bold">{translations.es.paragraph1}</p>
          <p>{translations.es.paragraph2}</p>
          <p className="bold">{translations.es.paragraph3}</p>
          <p>{translations.es.paragraph4}</p>
          <p className="bold">{translations.es.paragraph5}</p>
          <p>{translations.es.paragraph6}</p>
          <p>{translations.es.paragraph7}</p>
          <p>{translations.es.paragraph8}</p>
          <p>{translations.es.paragraph9}</p>
          <p>{translations.es.paragraph10}</p>
          <p>{translations.es.paragraph11}</p>
          <p>{translations.es.paragraph12}</p>
          <p className="bold">{translations.es.paragraph13}</p>
          <p>{translations.es.paragraph14}</p>
          <p>{translations.es.paragraph15}</p>
          <p>{translations.es.paragraph16}</p>
          <p>{translations.es.paragraph17}</p>
          <p>{translations.es.paragraph18}</p>
          <p>{translations.es.paragraph19}</p>
          <p className="bold">{translations.es.paragraph20}</p>
          <p>{translations.es.paragraph21}</p>
          <p>{translations.es.paragraph22}</p>
          <p>{translations.es.paragraph23}</p>
          <p>{translations.es.paragraph24}</p>
          <p>{translations.es.paragraph25}</p>
          <p>{translations.es.paragraph26}</p>
          <p className="bold">{translations.es.paragraph27}</p>
          <p>{translations.es.paragraph28}</p>
          <p>{translations.es.paragraph29}</p>
        </div>
      </main>
    </Layout>
  );
};

export default CookiePolicyPage;

export const Head = () => (
  <>
    <SeoES />
    <NoIndex />
  </>
);
